<template>
  <v-app-bar absolute color="#031D6A" dark max-height="" elevation="0">
    <!-- <v-btn small class="mx-2" fab dark color="indigo" to="/" title="Inicio">
      <v-img
        :src="require('@/assets/images/logoLeCleire.png')"
        contain
        width="5"
      />
    </v-btn> -->

    <div class="d-flex justify-center">
      <v-img alt="logo lecleire" class="shrink mt-2 ml-4 hidden-sm-and-down" contain min-width="200"
        :src="require('@/assets/images/logoLeCleire.png')" width="200" @click="gotoInicio()" />
    </div>
    <v-badge v-if="version != currentVersion.PANEL_VERSION"  :title="`Hay una nueva versión (${currentVersion.PANEL_VERSION}), Por favor refresca tu navegador o borra la caché.`" bordered color="error" icon="mdi-refresh" overlap>
      <v-chip color="indigo darken-2" disabled>v.{{ version }}</v-chip> 
    </v-badge>
    <v-badge v-else  dot bordered color="green ligthen-2" overlap>
      <v-chip color="indigo darken-2" disabled>v.{{ version }} </v-chip>
    </v-badge>
    <!-- <ping-monitor class="ml-1" /> -->


    <v-spacer></v-spacer>


    <v-btn v-if="flagLoggedIn" href="/" text>
      <span>Menu</span>
      <v-icon>mdi-dots-vertical</v-icon>
    </v-btn>

    <!-- /** MODULOS ASIGNADOS AL USUARIO INICIO */ -->
    <v-btn v-for="item in modulos" :key="item.id" small class="mx-2" fab dark text :to="item.url" :title="item.titulo">
      <v-icon dark> {{ item.icono }} </v-icon>
    </v-btn>
    <!-- /** MODULOS ASIGNADOS AL USUARIO FIN */ -->

    <!-- ícono con notificaciones -->
    <v-badge   v-if="showNotificaciones" class="mx-2" :content="total_notificaciones" :value="total_notificaciones" overlap color="red">
      <v-icon @click="fnMostratNotificaciones()"> mdi-bell </v-icon>
    </v-badge>

    <v-btn @click="cerrarSesion" v-if="flagLoggedIn" text title="Cerrar sesión">
      <span></span>
      <v-icon>mdi-power-standby</v-icon>
      {{ currentUser.usuario }}
    </v-btn>
    

    <!-- para el recuadro de las notificaciones -->
    <v-snackbar v-model="snackbar" timeout="-1" color="white" right top class="mt-14">
      <v-sheet id="scrolling-techniques-3" class="overflow-y-auto" max-height="300" color="white">
        <v-container fluid>

          <v-card flat color="white" v-if="notificaciones.length <= 0">
            <v-card-text class="black--text">sin notificaciones</v-card-text>
          </v-card>
          <v-card color="red" v-for="(item, i) in notificaciones" :key="i" max-width="300" class="mt-6">
            <v-row>
              <v-col cols="1" md="1">
                <v-icon class="pl-2">mdi-alert-circle-outline</v-icon>
              </v-col>
              <v-col cols="11" md="11">
                <p class="text-body-2 font-weight-regular white--text pl-4">
                  Evaluacion #{{ item.gestion_id }} {{ item.rectificacion_tipo }}
                </p>
                <p class="text-body-2 font-weight-regular white--text mt-n5 mb-n1 pl-4">
                  Motivo: {{ item.rectificacion_comentario }} <a
                    :href="`/detalle-gestion/${item.gestion_id}/Rechazado`">VER</a>
                </p>
              </v-col>
            </v-row>
          </v-card>
        </v-container>
      </v-sheet>
    </v-snackbar>
  </v-app-bar>
</template>
<script>
// import moment from 'moment';
// require('moment/locale/es.js');
// import PingMonitor from './components/PingMonitor.vue'
import { mapActions } from 'vuex';
export default {
  name: 'appBar',
  components: {
    // PingMonitor
  },
  data() {
    return {
      showNotificaciones: false,
      modulos: [],
      version: require('./../package.json').version,
      snackbar: false,

      flagLoggedIn: true, //variable temporal para mostrar el texto menú y lo de cerrar sesión

      total_notificaciones: 0, //variable que contendrá el número de notificaciones


      //notificaciones de ejemplo
      notificaciones: [

        // {
        //     nuEvaluacion: 6345,
        //     text: 'Los documentos pendientes del compromiso de pago no han sido completados y la fecha establecida (31/01/2024) han caducado.',
        //   },
        //   {
        //     nuEvaluacion: 6345,
        //     text: 'Los documentos pendientes del compromiso de pago no han sido completados y la fecha establecida (31/01/2024) han caducado.',
        //   },
        //   {
        //     nuEvaluacion: 6345,
        //     text: 'Los documentos pendientes del compromiso de pago no han sido completados y la fecha establecida (31/01/2024) han caducado.',
        //   },
      ],
    };
  },
  mounted() {
    if (this.currentUser.rol_nombre == 'Administrador') {
      this.modulos = this.$store.state.modulos;
    } else {
      this.modulos = this.$store.state.modulos.filter(modulo =>
        JSON.parse(this.currentUser.permisos_modulos).includes(modulo.url)
      );
    }

    //console.log('current routte', this.$route);
    this.main();
    //
    const {
      Rectificaciones
    } = this.$FeathersVuex.api;
    Rectificaciones.on('patched', () => {
      this.main();
    });
  },
  methods: {
    fnBuscarRectificacionesAtrasadas(){
      this.findRectificaciones({ query: this.getParams() }).then(r => {

      // Filtrar los registros cuyo atributo 'gestion.estado' no sea 'Gestión cancelada'
      const filteredNotificaciones = r.data.filter(item => item.gestion.estado !== 'Gestión cancelada');

      // Actualizar las notificaciones con las filtradas
      this.notificaciones = filteredNotificaciones;

      // Actualizar el total de notificaciones con la cantidad de notificaciones filtradas
      this.total_notificaciones = filteredNotificaciones.length;

      // this.notificaciones = r.data;
      // this.total_notificaciones = r.total
      })

    },
    main() {

      //console.log('current routte', this.$route);
        let vm = this;
        setTimeout(() => {
          if (
            vm.fnValidarPermiso(
              'campana-notificaciones'
            ) != -1
          ) {

            vm.fnBuscarRectificacionesAtrasadas()
            vm.showNotificaciones = true;
          }
        }, 1000);
      
    },
    fnVerRegistro(id) {
      this.$router.push(this.VUE_APP_FEATHERS_SERVER + `/detalle-gestion/${id}/Rechazado`);
    },
    getParams() {
      const params = {};

      const currentDate = new Date();
      const fiveDaysAgo = new Date();
      const tenDaysAgo = new Date();
      fiveDaysAgo.setDate(currentDate.getDate() - 5);
      tenDaysAgo.setDate(currentDate.getDate() - 10);

      // Filtro general de estado
      params['STATUS'] = {
        $in: [1, 4] // Solo las rectificaciones abiertas (status 1 y 4)
      };

      // Condiciones para los casos con o sin 'flag_compromiso_de_pago_id'
      params['$or'] = [
        {
          // Caso sin 'flag_compromiso_de_pago_id' (debe tener más de 5 días)
          flag_compromiso_de_pago_id: { $eq: null },
          createdAt: { $lt: fiveDaysAgo }
        },
        {
          // Caso con 'flag_compromiso_de_pago_id' (también más de 5 días)
          flag_compromiso_de_pago_id: { $ne: null },
          createdAt: { $lt: tenDaysAgo }
        }
      ];

      params['$limit'] = 500;

      return params;
    },
    ...mapActions('Gestiones', {
      findGestiones: 'find',
      createGestiones: 'create',
      patchGestiones: 'patch',
    }),
    ...mapActions('Rectificaciones', {
      findRectificaciones: 'find',
      createRectificaciones: 'create',
      patchRectificaciones: 'patch',
    }),
    async cerrarSesion() {
      await this.$store.dispatch('auth/logout');
      this.$router.push('/login');
    },

    gotoInicio() {
      this.$router.push('/');
      console.log('yendo a la pantalla del menú principal');
    },

    //funcion para mostrar las notificaciones
    fnMostratNotificaciones() {
      console.log('notificación on');
      this.snackbar = !this.snackbar; //mostramos y ocultamos las notificaciones
    },
    fnValidarPermiso(permiso) {

      if (
        this.$store.state.auth.payload.usuario.rol_nombre == 'Administrador'
      ) {
        return true;
      } else {
        let es_un_modulo_valido = this.$store.state.permisos.findIndex(
          (allowed_modulos) => allowed_modulos == permiso
        );
        return es_un_modulo_valido;
      }
    },
  },
  computed: {
    VUE_APP_FEATHERS_SERVER() {
      return process.env.VUE_APP_FEATHERS_SERVER;
    },
    currentUser() {
      return this.$store.state.auth.payload.usuario;
    },
    currentVersion() {
      return this.$store.state.auth.payload.system_configs;
    },
    /**
     * esta variable computada nos permite obtener la ruta en la que nos encontramos actualmente
     */
    rutaActual() {
      return this.$route;
    },

    // modulos() {
    //   return this.$store.state.store_modulos;
    // },
  },
};
</script>

<style scoped></style>
