<template>
  <v-container fluid>
    <div class="px-2 mt-2">
      <v-row justify="space-between">
        <v-col cols="3" md="3">

          <p class="text-body-1 font-weight-regular pt-2">
            Evaluacion #{{ detalleEvaluacion.id }}
          </p>

          <p class="text-body-2 text--secondary font-weight-regular mt-n3">
            <v-icon class="" color="gray">mdi-calendar-range</v-icon>
            Fecha evaluación: {{ fnGetDate(detalleEvaluacion.createdAt) }}
          </p>
        </v-col>

        <v-col cols="5" md="5">
          <div class="text-right">
            <v-btn small :loading="generando" text @click="exportToPDF()" class=" mr-3">
              <v-icon left>mdi-printer</v-icon>
              complementos
            </v-btn>
            <!-- {{ revision_deudor }} -->
            <!-- {{ revision_fiador }} -->
            <v-btn small
              v-if="!flag_gestion_terminada && router_path_filtro != 'ParaAprobacion' && router_path_filtro != 'CompromisosDePago'"
              :disabled="hasNullValue || bloquear" color="#031D6A" class=" white--text mr-4"
              @click="fnContinuarGestion()">
              CONTINUAR GESTIÓN
              <v-icon class="ml-2">mdi-check</v-icon>
            </v-btn>
            <v-progress-circular v-if="bloquear" class="mr-2"
              title="Estableciendo conexión con el servidor en Guatemala" indeterminate
              color="primary"></v-progress-circular>

            <cancelar_gestion :disabled="bloquear" v-if="!flag_gestion_terminada" :prop_etapa="router_path_filtro"
              :prop_btn_title="'CANCELAR GESTIÓN'" :prop_gestion_detalle="detalleEvaluacion"></cancelar_gestion>
          </div>
        </v-col>
      </v-row>
    </div>


    <!-- PARTE PRINCIPAL -->
    <v-card tile>
      <v-tabs color="#031D6A" left v-model="activeTab">
        <v-tab>DEUDOR</v-tab>
        <v-tab v-if="hasFiador && flag_fiador_tiene_algo_que_mostrar">
          FIADOR
        </v-tab>

        <!-- maquetado de DEUDOR -->
        <v-tab-item>
          <deudor :detalleEvaluacion="detalleEvaluacion" class="mt-4 pl-0 pr-0" />
        </v-tab-item>
        <v-tab-item v-if="hasFiador && flag_fiador_tiene_algo_que_mostrar">
          <fiador :detalleEvaluacion="detalleEvaluacion" class="mt-4 pl-0 pr-0" />
        </v-tab-item>
      </v-tabs>
    </v-card>


    <!-- Diálogo que aparece si existe rectificación con compromiso de pago -->
    <v-dialog v-model="dialogVisible" max-width="500">
      <v-card>
        <v-card-title class="headline">
          ¿Deseas continuar con la aprobación?
        </v-card-title>
        <v-card-text>
          Esta gestión tiene una rectificación(es) con compromiso de pago. ¿Estás seguro de que deseas enviarla para
          aprobación?
        </v-card-text>
        <v-card-actions>
          <v-btn color="green darken-1" text @click="confirmarForceContinueConCompromisos">Sí, continuar</v-btn>
          <v-btn color="red darken-1" text @click="cancelarAprobacion">No, cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import jsPDF from "jspdf";
import cancelar_gestion from './../cancelar_gestion.vue';
import { mapActions } from 'vuex';
import moment from 'moment';
require('moment/locale/es.js');
import deudor from './deudor/deudor.vue';
import fiador from './fiador/fiador.vue';
export default {
  name: 'detalleGestion',
  components: {
    cancelar_gestion,
    deudor, //vista con la info y todos los parámetros de DEUDOR
    fiador, //vista con la info y todos los componentes de FIADOR
  },
  data() {
    return {
      bloquear: false,
      activeTab: 0, // 0 = DEUDOR, 1 = FIADOR if visible
      generando: false,
      // Controla si hay una rectificación con compromiso de pago
      dialogVisible: false,
      forceContinueConCompromisos: false,
      /** */
      revision_deudor: {
        total_rectificaciones_pendientes: null,
        contrato_parte_uno_fotografia: null,
        contrato_parte_dos_fotografia: null,
        contrato_parte_tres_fotografia: null,
        identificacion_frontal: null,
        identificacion_anverso: null,
        identificacion_con_persona: null,
        recibo_de_servicio: null,
        fotografia_de_casa: null,
        referencia_titular: null,
        referencia_familiar_1: null,
        referencia_familiar_2: null,
        referencia_personal_1: null,
        referencia_personal_2: null,
        //
        recibo_comercial_1_id: null,
        recibo_comercial_2_id: null,
        //
        recibo_comercial_1_id_reverso: null,
        recibo_comercial_2_id_reverso: null,
        //
        recibo_de_servicio_direccion_entrega: null,
      },
      revision_fiador: {
        identificacion_frontal: null,
        identificacion_anverso: null,
        identificacion_con_persona: null,
        recibo_de_servicio: null,
        fotografia_de_casa: null,
        referencia_titular: null,
        referencia_familiar_1: null,
        referencia_familiar_2: null,
        referencia_personal_1: null,
        referencia_personal_2: null,
      },
      //datos (temporales) que se mostrarán en la columna de información personal
      detalleEvaluacion: {},
    };
  },
  methods: {
    async exportToPDF() {
      this.generando = true;
      console.log('Generando PDF con imágenes centradas...');

      const pdf = new jsPDF("p", "mm", "a4");
      const pageWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();

      for (let i = 0; i < this.imagenes.length; i++) {
        const imagePath = this.imagenes[i];
        const img = new Image();
        img.src = imagePath;

        // Espera a que la imagen se cargue
        await new Promise((resolve) => {
          img.onload = resolve;
        });

        const imgWidth = 190; // Ancho de la imagen (210mm - 20mm de margen total, 10mm a cada lado)
        const imgHeight = (img.height * imgWidth) / img.width; // Calcular la altura manteniendo la proporción

        // Posiciones x e y para centrar la imagen
        const x = (pageWidth - imgWidth) / 2;
        const y = (pageHeight - imgHeight) / 2;

        pdf.addImage(img, "JPEG", x, y, imgWidth, imgHeight);
        if (i < this.imagenes.length - 1) pdf.addPage(); // Añadir nueva página excepto en la última imagen
      }

      let perfil = this.activeTab === 0 ? 'deudor' : 'fiador';
      pdf.save(`complementos de la gestión ${this.detalleEvaluacion.id} (${perfil}).pdf`);
      this.generando = false;
    },
    ...mapActions('Gestiones', {
      findGestiones: 'find',
      getGestiones: 'get',
      patchGestiones: 'patch',
    }),
    ...mapActions('Rectificaciones', {
      findRectificaciones: 'find',
      getRectificaciones: 'get',
      patchRectificaciones: 'patch',
    }),
    ...mapActions('CompromisosDePago', {
      findCompromisosDePago: 'find',
      patchCompromisosDePago: 'patch',
    }),
    async main() {
      await this.getGestiones(this.router_path_id).then((r) => {
        this.detalleEvaluacion = r;

        if (r.flag_tiene_pedido) {
          if (r.flag_tiene_pedido == true) {
            r.flag_tiene_pedido = 1
          }

          if (r.flag_tiene_pedido == false) {
            r.flag_tiene_pedido = 0
          }

          if (!r.flag_tiene_pedido) {
            r.flag_tiene_pedido = null
          }

        }
        //console.log('detalleEvaluacion: ', r);
        /**
         *
         * DEUDOR
         */
        /** */
        //console.log('r.rectificacioneCreadasSinAtender', typeof r.rectificacioneCreadasSinAtender);

        if (r.rectificacioneCreadasSinAtender && r.rectificacioneCreadasSinAtender >= 1) {
          this.revision_deudor.total_rectificaciones_pendientes = 'Rechazado'
        } else {
          this.revision_deudor.total_rectificaciones_pendientes = 'Aprobado'
        }

        if (r.contrato && r.contrato.contrato_parte_uno_estado) {
          this.revision_deudor.contrato_parte_uno_fotografia =
            r.contrato.contrato_parte_uno_estado;
        }

        if (r.contrato && r.contrato.contrato_parte_dos_estado) {
          this.revision_deudor.contrato_parte_dos_fotografia =
            r.contrato.contrato_parte_dos_estado;
        }

        if (r.contrato && r.contrato.contrato_parte_tres_estado) {
          this.revision_deudor.contrato_parte_tres_fotografia =
            r.contrato.contrato_parte_tres_estado;
        }

        /** */
        this.revision_deudor.identificacion_frontal =
          r.documentos_de_identificacion.documento_de_identificacion_frontal_estado;
        this.revision_deudor.identificacion_anverso =
          r.documentos_de_identificacion.documento_de_identificacion_anverso_estado;
        this.revision_deudor.identificacion_con_persona =
          r.documentos_de_identificacion.documento_de_identificacion_con_persona_estado;
        /** */
        if (r.recibo_de_servicio && r.recibo_de_servicio.estado) {
          this.revision_deudor.recibo_de_servicio = r.recibo_de_servicio.estado;
        }
        /** */
        if (r.foto_casa && r.foto_casa.estado) {
          this.revision_deudor.fotografia_de_casa = r.foto_casa.estado;
        }
        /** */

        this.revision_deudor.referencia_titular = r.referencia_titular.estado;
        this.revision_deudor.referencia_familiar_1 =
          r.referencia_familiar_1.estado;
        this.revision_deudor.referencia_familiar_2 =
          r.referencia_familiar_2.estado;
        this.revision_deudor.referencia_personal_1 =
          r.referencia_personal_1.estado;
        this.revision_deudor.referencia_personal_2 =
          r.referencia_personal_2.estado;

        /** */
        if (r.recibo_comercial_1 && r.recibo_comercial_1.estado) {
          this.revision_deudor.recibo_comercial_1_id = r.recibo_comercial_1.estado;
        }
        if (r.recibo_comercial_2 && r.recibo_comercial_2.estado) {
          this.revision_deudor.recibo_comercial_2_id = r.recibo_comercial_2.estado;
        }
        /** */
        if (r.recibo_comercial_1_reverso && r.recibo_comercial_1_reverso.estado) {
          this.revision_deudor.recibo_comercial_1_id_reverso = r.recibo_comercial_1_reverso.estado;
        }
        if (r.recibo_comercial_2_reverso && r.recibo_comercial_2_reverso.estado) {
          this.revision_deudor.recibo_comercial_2_id_reverso = r.recibo_comercial_2_reverso.estado;
        }

        /** */
        if (r.recibo_de_servicio_direccion_entrega && r.recibo_de_servicio_direccion_entrega.estado) {
          this.revision_deudor.recibo_de_servicio_direccion_entrega = r.recibo_de_servicio_direccion_entrega.estado;
        }




        /**
         *
         * FIADOR
         */

        /** */
        if (r.fiador_documentos_de_identificacion) {
          this.revision_fiador.identificacion_frontal =
            r.fiador_documentos_de_identificacion.documento_de_identificacion_frontal_estado;
          this.revision_fiador.identificacion_anverso =
            r.fiador_documentos_de_identificacion.documento_de_identificacion_anverso_estado;
          this.revision_fiador.identificacion_con_persona =
            r.fiador_documentos_de_identificacion.documento_de_identificacion_con_persona_estado;
        }

        /** */
        if (r.fiador_recibo_de_servicio) {
          this.revision_fiador.recibo_de_servicio =
            r.fiador_recibo_de_servicio.estado;
        }

        /** */
        if (r.fiador_foto_casa) {
          this.revision_fiador.fotografia_de_casa = r.fiador_foto_casa.estado;
        }

        /** */

        if (r.fiador_referencia_titular)
          this.revision_fiador.referencia_titular =
            r.fiador_referencia_titular.estado;
        if (r.fiador_referencia_familiar_1)
          this.revision_fiador.referencia_familiar_1 =
            r.fiador_referencia_familiar_1.estado;
        if (r.fiador_referencia_familiar_2)
          this.revision_fiador.referencia_familiar_2 =
            r.fiador_referencia_familiar_2.estado;
        if (r.fiador_referencia_personal_1)
          this.revision_fiador.referencia_personal_1 =
            r.fiador_referencia_personal_1.estado;
        if (r.fiador_referencia_personal_2)
          this.revision_fiador.referencia_personal_2 =
            r.fiador_referencia_personal_2.estado;
      });
    },
    //
    fnGetDate(date) {
      return date ? moment(date).format('DD-MM-YYYY HH:mm a') : '';
    },
    /**se confirma que se debe continuar para aprobación aún con rectificacioens pero con compromisos de pago */
    confirmarForceContinueConCompromisos() {
      this.forceContinueConCompromisos = true;
      this.fnContinuarGestion();
    },
    cancelarAprobacion() {
      this.dialogVisible = false,
        this.forceContinueConCompromisos = false
    },
    //funcion para cuando se da clic en el botón de CONTINUAR GESTIÓN
    async fnContinuarGestion() {
      this.bloquear = true;
      setTimeout(async () => {
        await this.main();
      }, 1000);



      let updateGestionEstadosPayload = {
        flag_precalificacion_con_pendientes: this.hasPendienteValue,
        flag_precalificacion_con_rectificacion: this.hasRechazadoValue,
      };

      console.log(`informacion para banderas de rectificaciones y pendientes`, updateGestionEstadosPayload);


      /**
       * si tiene rectificaciones, vamos a verificar si no tienen compromiso de pago
       */
      if (this.hasRechazadoValue && this.forceContinueConCompromisos == false) {
        console.log('hay rechazados en la gestion, hay que revisar si hay compromisos aprobados');

        const rectificaciones = await this.findRectificaciones({
          query: {
            gestion_id: this.detalleEvaluacion.id,
            $limit: 50
          }
        });

        /**
         * validar si todas las rectificaciones son validas  (TRSV)
         */

        const TRSV = rectificaciones.data.some(item => item.flag_compromiso_de_pago_id !== null && item.STATUS !== 5);

        if (TRSV) {
          /**revisar si los compromisos de pago estan todos aprobados  */
          const compromisosDePago_idArrays = rectificaciones.data
            .filter(item => item.flag_compromiso_de_pago_id !== null)
            .map(item => item.flag_compromiso_de_pago_id);


          console.log('compromisosDePago_idArrays', compromisosDePago_idArrays);



          const compromisosDePago = await this.findCompromisosDePago({ query: { id: { $in: compromisosDePago_idArrays } } });

          console.log('compromisosDePago', compromisosDePago);


          if (compromisosDePago.total >= 1) {
            /**
          * validar si tods los compromisos de pago ya fueron aprobados (CPV)
          */

            console.log('existen compromisos de pago');


            const CPV = compromisosDePago.data.every(item => item.STATUS === 2);
            if (CPV && this.detalleEvaluacion.estado != 'Gestión concluida' && this.detalleEvaluacion.estado != 'Lista para aprobación') {
              console.log('existen compromisos de pago, pero todos son validos, CPV test', CPV);
              this.dialogVisible = true;
              return
            }
          }

        }
      }


      /**
       * si no tiene  ni pendientes ni rechazados, debe irse a listos para parobación de forma automatica
       */
      if (this.hasPendienteValue == false && this.hasRechazadoValue == false && this.detalleEvaluacion.flag_precalificacion_concluidas != 1) {
        console.log('si no tiene  ni pendientes ni rechazados, debe irse a listos para parobación de forma automatica');

        updateGestionEstadosPayload = Object.assign(
          updateGestionEstadosPayload,
          {
            flag_precalificacion_listo_para_aprobacion: 1,
            flag_precalificacion_listo_para_aprobacion_fecha: moment()
              .utc()
              .format('YYYY-MM-DDTHH:mm:ss.SSSS+00:00'),
          }
        );
      }

      if (!this.hasPendienteValue && !this.hasRechazadoValue && this.detalleEvaluacion.flag_precalificacion_concluidas != 1) {
        console.log('si no tiene  ni pendientes ni rechazados, debe irse a listos para parobación de forma automatica');

        updateGestionEstadosPayload = Object.assign(
          updateGestionEstadosPayload,
          {
            flag_precalificacion_listo_para_aprobacion: 1,
            flag_precalificacion_listo_para_aprobacion_fecha: moment()
              .utc()
              .format('YYYY-MM-DDTHH:mm:ss.SSSS+00:00'),
          }
        );
      }

      /**
       * mandarla para aprobación, aun con rectificacioens pendientes pero con compromisos de pago
       */

      if (this.forceContinueConCompromisos == true) {
        console.log('mandarla para aprobación, aun con rectificacioens pendientes pero con compromisos de pago');

        updateGestionEstadosPayload = Object.assign(
          updateGestionEstadosPayload,
          {
            flag_precalificacion_listo_para_aprobacion: 1,
            flag_precalificacion_listo_para_aprobacion_fecha: moment()
              .utc()
              .format('YYYY-MM-DDTHH:mm:ss.SSSS+00:00'),
          }
        );
      }

      /** */
      let mensaje = 'La revisión se ha marcado como completada.';
      if (this.hasRechazadoValue && this.hasPendienteValue) {
        mensaje = 'La revisión se ha marcado con rectificaciones y pendientes.';
      }
      if (!this.hasRechazadoValue && this.hasPendienteValue) {
        mensaje = 'La revisión se ha marcado con pendientes.';
      }
      if (this.hasRechazadoValue && !this.hasPendienteValue) {
        mensaje = 'La revisión se ha marcado con rectificaciones.';
      }
      await this.patchGestiones([
        this.detalleEvaluacion.id,
        updateGestionEstadosPayload,
      ]).then(() => {
        this.$notify({
          title: 'Revision completa',
          text: mensaje,
          color: '#2E7D32',
          timeout: 3000,
        });
        this.$store.state.globalLoading = true;

        let vm = this;

        setTimeout(() => {
          vm.bloquear = false;
          vm.$router.push('/'); //regresamos a gestiones en revisión
          vm.$store.state.globalLoading = false;
        }, 2000);
      }).catch(e => {
        this.$notify({
          title: 'Error',
          text: e,
          color: '#FF6F00',
          timeout: 5000,
        });
        this.main();
        this.bloquear = false;
      });
    },

    //funcion para cuando se da clic en botón de CANCELAR GESTIÓN
    fnCancelarGestion() {
      console.log('cancelar gestión');
    },
  },
  computed: {
    isDeudorActive() {
      return this.activeTab === 0;
    },
    isFiadorActive() {
      return this.activeTab === 1;
    },
    VUE_APP_FEATHERS_SERVER() {
      return process.env.VUE_APP_FEATHERS_SERVER;
    },
    imagenes() {
      let imagenes = [];

      if (this.activeTab == 0) {
        /**contratos */
        if (this.detalleEvaluacion.contrato && this.detalleEvaluacion.contrato.contrato_parte_uno_fotografia) {
          imagenes.push(this.VUE_APP_FEATHERS_SERVER + this.detalleEvaluacion.contrato.contrato_parte_uno_fotografia)
        }

        if (this.detalleEvaluacion.contrato && this.detalleEvaluacion.contrato.contrato_parte_dos_fotografia) {
          imagenes.push(this.VUE_APP_FEATHERS_SERVER + this.detalleEvaluacion.contrato.contrato_parte_dos_fotografia)
        }

        if (this.detalleEvaluacion.contrato && this.detalleEvaluacion.contrato.contrato_parte_tres_fotografia) {
          imagenes.push(this.VUE_APP_FEATHERS_SERVER + this.detalleEvaluacion.contrato.contrato_parte_tres_fotografia)
        }

        /**documentos de identificacion */
        if (this.detalleEvaluacion.documentos_de_identificacion && this.detalleEvaluacion.documentos_de_identificacion.documento_de_identificacion_frontal) {
          imagenes.push(this.VUE_APP_FEATHERS_SERVER + this.detalleEvaluacion.documentos_de_identificacion.documento_de_identificacion_frontal)
        }

        if (this.detalleEvaluacion.documentos_de_identificacion && this.detalleEvaluacion.documentos_de_identificacion.documento_de_identificacion_anverso) {
          imagenes.push(this.VUE_APP_FEATHERS_SERVER + this.detalleEvaluacion.documentos_de_identificacion.documento_de_identificacion_anverso)
        }

        if (this.detalleEvaluacion.documentos_de_identificacion && this.detalleEvaluacion.documentos_de_identificacion.documento_de_identificacion_con_persona) {
          imagenes.push(this.VUE_APP_FEATHERS_SERVER + this.detalleEvaluacion.documentos_de_identificacion.documento_de_identificacion_con_persona)
        }

        /**recibo de servicio */
        if (this.detalleEvaluacion.recibo_de_servicio && this.detalleEvaluacion.recibo_de_servicio.fotografia) {
          imagenes.push(this.VUE_APP_FEATHERS_SERVER + this.detalleEvaluacion.recibo_de_servicio.fotografia)
        }

        /**foto casa */
        if (this.detalleEvaluacion.foto_casa && this.detalleEvaluacion.foto_casa.fotografia) {
          imagenes.push(this.VUE_APP_FEATHERS_SERVER + this.detalleEvaluacion.foto_casa.fotografia)
        }

        /**recibo comercial 1 */
        if (this.detalleEvaluacion.recibo_comercial_1 && this.detalleEvaluacion.recibo_comercial_1.fotografia) {
          imagenes.push(this.VUE_APP_FEATHERS_SERVER + this.detalleEvaluacion.recibo_comercial_1.fotografia)
        }

        /**recibo comercial 2 */
        if (this.detalleEvaluacion.recibo_comercial_2 && this.detalleEvaluacion.recibo_comercial_2.fotografia) {
          imagenes.push(this.VUE_APP_FEATHERS_SERVER + this.detalleEvaluacion.recibo_comercial_2.fotografia)
        }

        /**recibo comercial 1 _reverso */
        if (this.detalleEvaluacion.recibo_comercial_1_reverso && this.detalleEvaluacion.recibo_comercial_1_reverso.fotografia) {
          imagenes.push(this.VUE_APP_FEATHERS_SERVER + this.detalleEvaluacion.recibo_comercial_1_reverso.fotografia)
        }

        /**recibo comercial 2 _reverso */
        if (this.detalleEvaluacion.recibo_comercial_2_reverso && this.detalleEvaluacion.recibo_comercial_2_reverso.fotografia) {
          imagenes.push(this.VUE_APP_FEATHERS_SERVER + this.detalleEvaluacion.recibo_comercial_2_reverso.fotografia)
        }


        /**recibo servicio direccion de entrega */
        if (this.detalleEvaluacion.recibo_de_servicio_direccion_entrega && this.detalleEvaluacion.recibo_de_servicio_direccion_entrega.fotografia) {
          imagenes.push(this.VUE_APP_FEATHERS_SERVER + this.detalleEvaluacion.recibo_de_servicio_direccion_entrega.fotografia)
        }

      } else {

        /**documentos de identificacion */
        if (this.detalleEvaluacion.fiador_documentos_de_identificacion && this.detalleEvaluacion.fiador_documentos_de_identificacion.documento_de_identificacion_frontal) {
          imagenes.push(this.VUE_APP_FEATHERS_SERVER + this.detalleEvaluacion.fiador_documentos_de_identificacion.documento_de_identificacion_frontal)
        }

        if (this.detalleEvaluacion.fiador_documentos_de_identificacion && this.detalleEvaluacion.fiador_documentos_de_identificacion.documento_de_identificacion_anverso) {
          imagenes.push(this.VUE_APP_FEATHERS_SERVER + this.detalleEvaluacion.fiador_documentos_de_identificacion.documento_de_identificacion_anverso)
        }

        if (this.detalleEvaluacion.fiador_documentos_de_identificacion && this.detalleEvaluacion.fiador_documentos_de_identificacion.documento_de_identificacion_con_persona) {
          imagenes.push(this.VUE_APP_FEATHERS_SERVER + this.detalleEvaluacion.fiador_documentos_de_identificacion.documento_de_identificacion_con_persona)
        }

        /**recibo de servicio */
        if (this.detalleEvaluacion.fiador_recibo_de_servicio && this.detalleEvaluacion.fiador_recibo_de_servicio.fotografia) {
          imagenes.push(this.VUE_APP_FEATHERS_SERVER + this.detalleEvaluacion.fiador_recibo_de_servicio.fotografia)
        }

        /**foto casa */
        if (this.detalleEvaluacion.fiador_foto_casa && this.detalleEvaluacion.fiador_foto_casa.fotografia) {
          imagenes.push(this.VUE_APP_FEATHERS_SERVER + this.detalleEvaluacion.fiador_foto_casa.fotografia)
        }

      }

      return imagenes;
    },
    router_path_id() {
      return this.$route.params.id;
    },
    router_path_filtro() {
      if (this.$route.params.filtro) return this.$route.params.filtro;
      else return null;
    },
    hasNullValue() {
      let filteredRevisionDeudor = Object.assign(this.revision_deudor);

      // Check if we need to ignore specific attributes
      if (this.detalleEvaluacion.contrato_id === null && this.detalleEvaluacion.buro_tipo_de_usuario == 'reingreso') {
        delete filteredRevisionDeudor.contrato_parte_uno_fotografia;
        delete filteredRevisionDeudor.contrato_parte_dos_fotografia;
        delete filteredRevisionDeudor.contrato_parte_tres_fotografia;
      }

      if (this.detalleEvaluacion.recibo_comercial_1_id === null) {
        delete filteredRevisionDeudor.recibo_comercial_1_id;
      }

      if (this.detalleEvaluacion.recibo_comercial_2_id === null) {
        delete filteredRevisionDeudor.recibo_comercial_2_id;
      }

      if (this.detalleEvaluacion.recibo_comercial_1_id_reverso === null) {
        delete filteredRevisionDeudor.recibo_comercial_1_id_reverso;
      }

      if (this.detalleEvaluacion.recibo_comercial_2_id_reverso === null) {
        delete filteredRevisionDeudor.recibo_comercial_2_id_reverso;
      }


      if (this.detalleEvaluacion.recibo_comercial_1_id_reverso === undefined) {
        delete filteredRevisionDeudor.recibo_comercial_1_id_reverso;
      }

      if (this.detalleEvaluacion.recibo_comercial_2_id_reverso === undefined) {
        delete filteredRevisionDeudor.recibo_comercial_2_id_reverso;
      }

      if (this.detalleEvaluacion.recibo_de_servicio_id === null) {
        delete filteredRevisionDeudor.recibo_de_servicio;
      }

      if (this.detalleEvaluacion.fotografia_de_casa_id === null) {
        delete filteredRevisionDeudor.fotografia_de_casa;
      }

      if (this.detalleEvaluacion.recibo_de_servicio_direccion_entrega_id === null) {
        delete filteredRevisionDeudor.recibo_de_servicio_direccion_entrega;
      }



      let filteredRevisionFiador = Object.assign(this.revision_fiador);

      if (this.detalleEvaluacion.fiador_recibo_de_servicio_id === null) {
        delete filteredRevisionFiador.recibo_de_servicio;
      }

      if (this.detalleEvaluacion.fiador_fotografia_de_casa_id === null) {
        delete filteredRevisionFiador.fotografia_de_casa;
      }


      if (this.hasFiador) {
        if (
          Object.values(filteredRevisionDeudor).includes(null) == false &&
          Object.values(filteredRevisionFiador).includes(null) == false
        )
          return false;
        else return true;
      } else {
        if (Object.values(filteredRevisionDeudor).includes(null) == false)
          return false;
        else return true;
      }
    },

    hasPendienteValue() {
      if (this.hasFiador) {
        if (
          Object.values(this.revision_deudor).includes('Pendiente') == false &&
          Object.values(this.revision_fiador).includes('Pendiente') == false
        )
          return null;
        else return true;
      } else {
        if (Object.values(this.revision_deudor).includes('Pendiente') == false)
          return null;
        else return true;
      }
    },

    hasRechazadoValue() {
      if (this.hasFiador) {
        if (
          Object.values(this.revision_deudor).includes('Rechazado') == false &&
          Object.values(this.revision_fiador).includes('Rechazado') == false
        ) {
          console.log('revision de complementos del deudor: ', this.revision_deudor);
          console.log('revision de complementos del fiador: ', this.revision_fiador);
          return null;
        }

        else return true;
      } else {

        console.log('revision de complementos del deudor: ', this.revision_deudor);

        if (Object.values(this.revision_deudor).includes('Rechazado') == false)
          return null;
        else return true;
      }
    },

    hasFiador() {
      if (this.detalleEvaluacion.fiador_primer_nombre == null) {
        return false;
      } else {
        return true;
      }
    },

    flag_deudor_tiene_algo_que_mostrar() {
      if (!this.router_path_filtro || this.router_path_filtro == 'ParaAprobacion' || this.router_path_filtro == 'CompromisosDePago') {
        return true;
      } else {
        if (
          Object.values(this.revision_deudor).includes('Rechazado') == false &&
          Object.values(this.revision_deudor).includes('Pendiente') == false
        ) {
          return false;
        } else {
          return true;
        }
      }
    },

    flag_fiador_tiene_algo_que_mostrar() {
      if (!this.router_path_filtro) {
        return true;
      } else if (this.router_path_filtro == 'CompromisosDePago') {
        return false;
      }
      else {
        if (
          Object.values(this.revision_fiador).includes('Rechazado') == false &&
          Object.values(this.revision_fiador).includes('Pendiente') == false &&
          this.revision_deudor.total_rectificaciones_pendientes == 'Aprobado'
        ) {
          return false;
        } else {
          return true;
        }
      }
    },
    flag_gestion_terminada() {
      if (this.detalleEvaluacion.flag_precalificacion_concluidas == 1 && this.detalleEvaluacion.flag_precalificacion_con_rectificacion == null && this.detalleEvaluacion.flag_precalificacion_con_pendientes == null) {
        return true
      } else if (this.detalleEvaluacion.flag_precalificacion_cancelada == 1) {
        return true
      } else if (this.detalleEvaluacion.flag_precalificacion_eliminada == 1) {
        return true
      } else {
        return false
      }
    }
  },
  mounted() {
    this.main();

    const {
      Contratos,
      DocumentosDeIdentificacion,
      Recibos,
      FotografiasDeCasa,
      Referencias,
      RecibosComerciales
    } = this.$FeathersVuex.api;
    Contratos.on('patched', () => {
      this.main();
    });
    DocumentosDeIdentificacion.on('patched', () => {
      this.main();
    });
    Recibos.on('patched', () => {
      this.main();
    });
    FotografiasDeCasa.on('patched', () => {
      this.main();
    });
    Referencias.on('patched', () => {
      this.main();
    });
    RecibosComerciales.on('patched', () => {
      this.main();
    });
  },
};
</script>

<style scoped>
table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 5px;
}
</style>
