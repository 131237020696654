<template>
  <v-container fluid>
    <!-- PARTE PRINCIPAL -->
    <v-row class="px-2 mt-n6">
      <!-- columna izquierda con la informacíon de la persona -->
      <v-col cols="4" md="4" xl="3">
        <v-card tile>
          <div class="text-center">
            <v-avatar size="58" color="#031D6A" class="my-3">
              <v-icon dark size="48"> mdi-account </v-icon>
            </v-avatar>
            <p class="text-h6 text--primary font-weight-regular mt-n2">
              {{ detalleEvaluacion.deudor_nombre_completo }}
            </p>
          </div>
          <div class="px-4 mt-n4 pb-2">
            <table>
              <tr>
                <td class="text--secondary font-weight-bold text-body-2">
                  Número de documento identifiación
                </td>
                <td class="text--secondary font-weight-regular text-body-2" align="right">
                  {{ detalleEvaluacion.dpi }}
                </td>
              </tr>

              <tr>
                <td class="text--secondary font-weight-bold text-body-2">
                  Identificación extendida en
                </td>
                <td class="text--secondary font-weight-regular text-body-2" align="right">
                  {{
                    detalleEvaluacion.identificacion_extendida_en_departamento
                  }}
                </td>
              </tr>
              <tr>
                <td class="text--secondary font-weight-bold text-body-2">
                  Estado de la gestion
                </td>
                <td
                  :class="[detalleEvaluacion.estado == 'Gestión cancelada' ? 'red--text font-weight-bold text-body-2' : 'text--secondary font-weight-regular text-body-2']"
                  align="right">
                  {{ detalleEvaluacion.estado }}
                </td>
              </tr>

              <tr v-if="detalleEvaluacion.estado == 'Gestión cancelada'">
                <td class="red--text  font-weight-bold text-body-2">
                  Cancelado por
                </td>
                <td :class="'text--secondary font-weight-regular text-body-2'" align="right">
                  {{ detalleEvaluacion.flag_precalificacion_cancelada_por_usuario }}
                </td>
              </tr>

              <tr v-if="detalleEvaluacion.estado == 'Gestión cancelada'">
                <td class="red--text font-weight-bold text-body-2">
                  Fecha de cancelación
                </td>
                <td :class="'text--secondary font-weight-regular text-body-2'" align="right">
                  {{ fnGetDateTime(detalleEvaluacion.flag_precalificacion_cancelada_fecha) }}
                </td>
              </tr>

              <tr v-if="detalleEvaluacion.estado == 'Gestión cancelada'">
                <td class="red--text  font-weight-bold text-body-2">
                  Comentario cancelación
                </td>
                <td :class="'text--secondary font-weight-regular text-body-2'" align="right">
                  {{ detalleEvaluacion.flag_precalificacion_cancelada_motivo }}
                </td>
              </tr>

              <tr>
                <td class="text--secondary font-weight-bold text-body-2">
                  Buro resultado
                </td>
                <td class="text--secondary font-weight-regular text-body-2" align="right">
                  <span :class="getBuroColor(detalleEvaluacion.resultado_evaluacion) + '--text'">{{
                    detalleEvaluacion.resultado_evaluacion }}</span>
                </td>
              </tr>
              <tr>
                <td class="text--secondary font-weight-bold text-body-2">
                  Buro tipo de usuario
                </td>
                <td class="text--secondary font-weight-regular text-body-2" align="right">
                  <strong>{{ detalleEvaluacion.buro_tipo_de_usuario }}</strong>
                </td>
              </tr>
              <tr>
                <td class="text--secondary font-weight-bold text-body-2">
                  Fecha de inicio
                </td>
                <td class="text--secondary font-weight-regular text-body-2" align="right">
                  {{ fnGetDateTime(detalleEvaluacion.createdAt) }}
                </td>
              </tr>

              <tr v-if="fecha_finish">
                <td class="text--secondary font-weight-bold text-body-2">
                  Fecha de culminación
                </td>
                <td class="text--secondary font-weight-regular text-body-2" align="right">
                  {{ fnGetDateTime(fecha_finish) }}
                </td>
              </tr>

              <tr>
                <td class="text--secondary font-weight-bold text-body-2">
                  Primer nombre
                </td>
                <td class="text--secondary font-weight-regular text-body-2" align="right">
                  {{ detalleEvaluacion.primer_nombre }}
                </td>
              </tr>

              <tr>
                <td class="text--secondary font-weight-bold text-body-2">
                  Segundo nombre
                </td>
                <td class="text--secondary font-weight-regular text-body-2" align="right">
                  <!-- mostramos un guión mayor si no contiene el campo, si es una cadena vacía  -->
                  <span v-if="detalleEvaluacion.segundo_nombre">{{
                    detalleEvaluacion.segundo_nombre
                  }}</span>
                  <span v-if="!detalleEvaluacion.segundo_nombre">-</span>
                </td>
              </tr>

              <tr>
                <td class="text--secondary font-weight-bold text-body-2">
                  Tercer nombre
                </td>
                <td class="text--secondary font-weight-regular text-body-2" align="right">
                  <span v-if="detalleEvaluacion.tercer_nombre">{{
                    detalleEvaluacion.tercer_nombre
                  }}</span>
                  <span v-if="!detalleEvaluacion.tercer_nombre">-</span>
                </td>
              </tr>

              <tr>
                <td class="text--secondary font-weight-bold text-body-2">
                  Primer apellido
                </td>
                <td class="text--secondary font-weight-regular text-body-2" align="right">
                  {{ detalleEvaluacion.primer_apellido }}
                </td>
              </tr>

              <tr>
                <td class="text--secondary font-weight-bold text-body-2">
                  Segundo apellido
                </td>
                <td class="text--secondary font-weight-regular text-body-2" align="right">
                  {{ detalleEvaluacion.segundo_apellido }}
                </td>
              </tr>

              <tr>
                <td class="text--secondary font-weight-bold text-body-2">
                  Tercer apellido
                </td>
                <td class="text--secondary font-weight-regular text-body-2" align="right">
                  <span v-if="detalleEvaluacion.tercer_apellido_o_casada">{{
                    detalleEvaluacion.tercer_apellido_o_casada
                  }}</span>
                  <span v-if="!detalleEvaluacion.tercer_apellido_o_casada">-</span>
                </td>
              </tr>

              <tr>
                <td class="text--secondary font-weight-bold text-body-2">
                  Fecha nacimiento
                </td>
                <td class="text--secondary font-weight-regular text-body-2" align="right">
                  {{ fnGetDateUTC(detalleEvaluacion.fecha_de_nacimiento) }}
                </td>
              </tr>

              <tr>
                <td class="text--secondary font-weight-bold text-body-2">
                  Dirección de la consejera
                </td>
                <td class="text--secondary font-weight-regular text-body-2" align="right">
                  {{ detalleEvaluacion.consejera_direccion }}
                </td>
              </tr>

              <tr>
                <td class="text--secondary font-weight-bold text-body-2">
                  Dirección entrega
                </td>
                <td class="text--secondary font-weight-regular text-body-2" align="right">
                  {{ detalleEvaluacion.entrega_direccion }}
                </td>
              </tr>

              <tr>
                <td class="text--secondary font-weight-bold text-body-2">
                  Tipo de vivienda
                </td>
                <td class="text--secondary font-weight-regular text-body-2" align="right">
                  {{ detalleEvaluacion.vivienda_tipo }}
                </td>
              </tr>

              <tr>
                <td class="text--secondary font-weight-bold text-body-2">
                  Ubicación
                </td>
                <td class="text--secondary font-weight-regular text-body-2" align="right">
                  <span v-if="detalleEvaluacion.gestion_ubicacion_lat">{{ detalleEvaluacion.gestion_ubicacion_lat }},
                    {{ detalleEvaluacion.gestion_ubicacion_lng }}</span>
                  <span v-if="!detalleEvaluacion.gestion_ubicacion_lat">-</span>
                </td>
              </tr>

              <tr>
                <td class="text--secondary font-weight-bold text-body-2">
                  Número de celular
                </td>
                <td class="text--secondary font-weight-regular text-body-2" align="right">
                  {{ detalleEvaluacion.numero_de_celular }}
                </td>
              </tr>

              <tr>
                <td class="text--secondary font-weight-bold text-body-2">
                  Teléfono de casa
                </td>
                <td class="text--secondary font-weight-regular text-body-2" align="right">
                  {{ detalleEvaluacion.telefono_de_casa }}
                </td>
              </tr>

              <tr>
                <td class="text--secondary font-weight-bold text-body-2">
                  Dirección de trabajo
                </td>
                <td class="text--secondary font-weight-regular text-body-2" align="right">
                  {{ detalleEvaluacion.trabajo_direccion }}
                </td>
              </tr>

              <tr>
                <td class="text--secondary font-weight-bold text-body-2">
                  Teléfono de trabajo
                </td>
                <td class="text--secondary font-weight-regular text-body-2" align="right">
                  {{ detalleEvaluacion.trabajo_telefono }}
                </td>
              </tr>

              <tr>
                <td class="text--secondary font-weight-bold text-body-2">
                  NIT
                </td>
                <td class="text--secondary font-weight-regular text-body-2" align="right">
                  {{ detalleEvaluacion.nit }}
                </td>
              </tr>

              <tr>
                <td class="text--secondary font-weight-bold text-body-2">
                  Contado
                </td>
                <td class="text--secondary font-weight-regular text-body-2" align="right">
                  {{ detalleEvaluacion.contado }}
                </td>
              </tr>
            </table>
          </div>
        </v-card>
      </v-col>

      <!-- columna derecha -->
      <v-col cols="8" md="8" xl="9">
        <v-card tile min-height="570">
          <v-tabs v-model="tab" color="#031D6A" left>
            <v-tab :disabled="!flag_show_contrato" key="contado">CONTRATO</v-tab>
            <v-tab :disabled="!flag_show_complemento" key="complemento">COMPLEMENTOS</v-tab>
            <v-tab :disabled="false" key="evaluacion">EVALUACIÓN</v-tab>
            <v-tab :disabled="!flag_show_referencias" key="referencias">REFERENCIAS</v-tab>
            <v-tab active v-if="flag_show_compromisos" key="compromisos">COMPROMISOS DE PAGO</v-tab>

            <v-tab active v-if="flag_show_rectifiaciones" key="rectifiaciones">RECTIFICACIONES</v-tab>

            <v-tab active v-if="flag_show_paraaprobacion" key="rectifiaciones">Para Aprobación</v-tab>

            <!-- maquetado de CONTRATO -->
            <v-tab-item>
              <vistaContrato :detalleEvaluacion="detalleEvaluacion" class="mb-n6" />
            </v-tab-item>
            <!-- maquetado vista COMPLEMENTOS -->
            <v-tab-item>
              <vistaComplementos :detalleEvaluacion="detalleEvaluacion" />
            </v-tab-item>
            <!-- maquetado vista EVALUACIÓN -->
            <v-tab-item>
              <vistaEvaluacion :detalleEvaluacion="detalleEvaluacion" />
            </v-tab-item>
            <!-- maquetado vista REFERENCIAS -->
            <v-tab-item>
              <vistaReferencias :detalleEvaluacion="detalleEvaluacion" />
            </v-tab-item>
            <v-tab-item v-if="flag_show_compromisos">
              <vistaCompromisosDeudor :detalleEvaluacion="detalleEvaluacion" />
            </v-tab-item>
            <v-tab-item v-if="flag_show_rectifiaciones">
              <vistaRectificacionesDeudor :prop_perfil="'deudor'" :detalleEvaluacion="detalleEvaluacion" />
            </v-tab-item>
            <v-tab-item v-if="flag_show_paraaprobacion">
              <VistaAprobacionGestion :prop_perfil="'deudor'" :detalleEvaluacion="detalleEvaluacion" />
            </v-tab-item>
          </v-tabs>


        </v-card>
        <v-card>
          <!-- apartado para la sección de comentarios -->
          <v-container>
            <v-row>
              <v-col cols="6" v-show="!router_path_filtro ||
                (router_path_filtro && router_path_filtro == 'Rechazado')
                ">
                <!-- Card para mostrar el/los comentarios -->
                <span class="text--secondary" v-if="
                  !filtered_restricciones_comentarios ||
                  filtered_restricciones_comentarios.length == 0
                ">Sin rectificaciones</span>
                <v-card class="mb-2" outlined min-height="130" v-for="(
                      comentario, j
                    ) in filtered_restricciones_comentarios" :key="j">
                  <v-card-title>
                    {{
                      comentario.complemento_nombre
                        .replace(/_/g, ' ')
                        .replace(/\b\w/g, (char) => char.toUpperCase())
                        .replace(/Estado/g, ' ')
                    }}
                  </v-card-title>
                  <v-card-text>
                    <p>
                      <span class="font-weight-medium">Motivo:</span>
                      {{ comentario.rectificacion_comentario }}
                    </p>
                    <hr class="mb-2" style="
                          align: left;
                          max-width: 150px;
                          border: 1px solid #eeeeee;
                        " />
                    <p>
                      Rectificaciíon creada por:
                      {{ comentario.CREADO_POR_USUARIO }} <br />
                      Fecha de creación:
                      {{ fnGetDateTime(comentario.createdAt) }}
                    </p>
                  </v-card-text>
                  <v-card-actions> </v-card-actions>
                </v-card>
              </v-col>

              <v-col cols="6" v-if="router_path_filtro != 'ParaAprobacion'">
                <v-textarea ref="comentario_interno" v-model="comentario_interno" label="Agregar comentario" auto-grow
                  outlined rows="4" row-height="25"></v-textarea>

                <v-btn small color="blue" outlined class="px-4 mr-1 mt-n8" elevation="2"
                  @click="fn_agregar_comentario_interno()">
                  AGREGAR COMENTARIO interno
                </v-btn>
                <v-btn small color="cyan" outlined class="px-4 mt-n8" elevation="2"
                  @click="fn_agregar_comentario_externo()">
                  Enviar comentario a agente de comercio
                </v-btn>
                <v-card flat v-if="filtered_comentarios_internos.length <= 0">
                  <v-card-text>Sin comentarios</v-card-text>
                </v-card>
                <!-- Card para mostrar el/los comentarios -->
                <v-card class="mb-2" outlined min-height="130"
                  v-for="(intComentario, j) in filtered_comentarios_internos" :key="j">
                  <v-container>
                    <v-row>
                      <v-col cols="1">
                        <v-icon v-if="!intComentario.flag_comentario_externo" class="mt-3">mdi-comment</v-icon>
                        <v-icon v-if="intComentario.flag_comentario_externo" class="mt-3">mdi-cellphone</v-icon>
                      </v-col>
                      <v-col cols="11">
                        <p class="text-body-2 font-weight-medium text--secondary">
                          Comentario por:
                          {{ intComentario.CREADO_POR_USUARIO }}
                        </p>
                        <p class="text-body-2 font-weight-medium text--secondary mt-n4">
                          Fecha: {{ fnGetDateTime(intComentario.createdAt) }}
                        </p>
                      </v-col>
                    </v-row>
                    <p class="text-body-2 font-weight-regular text--secondary mt-n5">
                      {{ intComentario.comentario }}
                    </p>
                  </v-container>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import VistaAprobacionGestion from '../listasParaAprobacion/vista_aprobar_gestion.vue'
import { mapActions } from 'vuex';
import moment from 'moment';
require('moment/locale/es.js');
import vistaContrato from './vistaContratoDeudor.vue';
import vistaComplementos from './vistaComplementosDeudor.vue';
import vistaEvaluacion from './vistaEvaluacionDeudor.vue';
import vistaReferencias from './vistaReferenciasDeudor.vue';
import vistaCompromisosDeudor from './vistaCompromisosDeudor.vue';
import vistaRectificacionesDeudor from './vistaRectificaciones.vue';

export default {
  name: 'deudorPage',
  props: ['detalleEvaluacion'],
  components: {
    VistaAprobacionGestion,
    vistaContrato, //componente que contiene info de la ventana de CONTRATO
    vistaComplementos, //componente que contiene la info de la ventana de COMPLEMENTOS
    vistaEvaluacion, // para la info que se muestra en EVALUACIÓN
    vistaReferencias, //para la info que se muestra en REFERENCIAS
    vistaCompromisosDeudor, //para la info que se muestra en vistaCompromisosDeudor
    vistaRectificacionesDeudor, //para la info que se muestra en vistaRectificacionesDeudor
  },
  data() {
    return {
      //
      tab: 0,
      //
      comentario_interno: '',
      comentarios_internos: [],
      //
      comentarios_rectificaciones: [],
    };
  },
  computed: {
    flag_gestion_terminada() {
      if (this.detalleEvaluacion.flag_precalificacion_concluidas == 1) {
        return true
      } else if (this.detalleEvaluacion.flag_precalificacion_cancelada == 1) {
        return true
      } else if (this.detalleEvaluacion.flag_precalificacion_eliminada == 1) {
        return true
      } else {
        return false
      }
    },
    fecha_finish() {
      if (this.detalleEvaluacion.flag_precalificacion_concluidas == 1) {
        return this.detalleEvaluacion.flag_precalificacion_concluidas_fecha
      } else if (this.detalleEvaluacion.flag_precalificacion_cancelada == 1) {
        return this.detalleEvaluacion.flag_precalificacion_cancelada_fecha
      } else if (this.detalleEvaluacion.flag_precalificacion_eliminada == 1) {
        return this.detalleEvaluacion.flag_precalificacion_eliminada_fecha
      } else {
        return false
      }
    },
    currentUser() {
      return this.$store.state.auth.payload.usuario;
    },
    router_path_id() {
      return this.$route.params.id;
    },
    router_path_filtro() {
      if (this.$route.params.filtro) return this.$route.params.filtro;
      else return null;
    },
    filtered_restricciones_comentarios() {
      switch (this.tab) {
        case 0:
          return this.comentarios_rectificaciones.filter(
            (item) => item.rectificacion_tipo === 'contrato'
          );
        case 1:
          return this.comentarios_rectificaciones.filter((item) =>
            [
              'documento de identificación',
              'recibo de servcio',
              'fotografía de casa',
            ].includes(item.rectificacion_tipo)
          );
        case 2:
          return this.comentarios_rectificaciones.filter(
            (item) => item.rectificacion_tipo === 'evaluacion'
          );
        case 3:
          return this.comentarios_rectificaciones.filter(
            (item) => item.rectificacion_tipo === 'referencias'
          );
        default:
          return this.comentarios_rectificaciones;
      }
    },
    filtered_comentarios_internos() {
      switch (this.tab) {
        case 0:
          return this.comentarios_internos.filter(
            (item) => item.comentario_tipo === 'contrato'
          );
        case 1:
          return this.comentarios_internos.filter(
            (item) => item.comentario_tipo === 'complementos'
          );
        case 2:
          return this.comentarios_internos.filter(
            (item) => item.comentario_tipo === 'evaluacion'
          );
        case 3:
          return this.comentarios_internos.filter(
            (item) => item.comentario_tipo === 'referencias'
          );
        default:
          return this.comentarios_internos;
      }
    },
    /** */
    flag_show_contrato() {
      if (!this.router_path_filtro) {
        return true;
      } else {
        if (this.detalleEvaluacion.contrato) {
          let contrato = this.detalleEvaluacion.contrato;
          if (contrato.contrato_parte_uno_estado == this.router_path_filtro) {
            return true;
          }
          if (contrato.contrato_parte_dos_estado == this.router_path_filtro) {
            return true;
          }
          if (contrato.contrato_parte_tres_estado == this.router_path_filtro) {
            return true;
          }
        }
        return false;
      }
    },
    flag_show_complemento() {
      if (!this.router_path_filtro) {
        return true;
      } else {
        /** complemento doc */
        if (this.detalleEvaluacion.documentos_de_identificacion) {
          let identifiacion_documento =
            this.detalleEvaluacion.documentos_de_identificacion;

          if (
            identifiacion_documento.documento_de_identificacion_frontal_estado ==
            this.router_path_filtro
          ) {
            return true;
          }
          if (
            identifiacion_documento.documento_de_identificacion_anverso_estado ==
            this.router_path_filtro
          ) {
            return true;
          }
          if (
            identifiacion_documento.documento_de_identificacion_con_persona_estado ==
            this.router_path_filtro
          ) {
            return true;
          }
        }
        /** complemento recibo */
        if (this.detalleEvaluacion.recibo_de_servicio) {
          if (
            this.detalleEvaluacion.recibo_de_servicio.estado ==
            this.router_path_filtro
          ) {
            return true;
          }
        }
        /** complemento recibo de comercio 1 */
        if (this.detalleEvaluacion.recibo_comercial_1) {
          if (
            this.detalleEvaluacion.recibo_comercial_1.estado ==
            this.router_path_filtro
          ) {
            return true;
          }
        }
        /** complemento recibo de comercio 2 */
        if (this.detalleEvaluacion.recibo_comercial_2) {
          if (
            this.detalleEvaluacion.recibo_comercial_2.estado ==
            this.router_path_filtro
          ) {
            return true;
          }
        }
        /** complemento recibo de comercio 1 _reverso*/
        if (this.detalleEvaluacion.recibo_comercial_1_reverso) {
          if (
            this.detalleEvaluacion.recibo_comercial_1_reverso.estado ==
            this.router_path_filtro
          ) {
            return true;
          }
        }
        /** complemento recibo de comercio 2 _reverso*/
        if (this.detalleEvaluacion.recibo_comercial_2_reverso) {
          if (
            this.detalleEvaluacion.recibo_comercial_2_reverso.estado ==
            this.router_path_filtro
          ) {
            return true;
          }
        }
        /** complemento recibo_de_servicio_direccion_entrega */
        if (this.detalleEvaluacion.recibo_de_servicio_direccion_entrega) {
          if (
            this.detalleEvaluacion.recibo_de_servicio_direccion_entrega.estado ==
            this.router_path_filtro
          ) {
            return true;
          }
        }
        /** complemento casa */
        if (this.detalleEvaluacion.foto_casa) {
          if (
            this.detalleEvaluacion.foto_casa.estado == this.router_path_filtro
          ) {
            return true;
          }
        }
        return false;
      }
    },
    flag_show_evaluacion() {
      if (!this.router_path_filtro) {
        return true;
      } else {
        if (this.detalleEvaluacion.evaluacion) {
          return true;
        }
        return false;
      }
    },
    flag_show_referencias() {
      if (!this.router_path_filtro) {
        return true;
      } else {
        if (this.detalleEvaluacion) {
          if (
            this.detalleEvaluacion.referencia_titular &&
            this.detalleEvaluacion.referencia_titular.estado ==
            this.router_path_filtro
          ) {
            return true;
          }
          if (
            this.detalleEvaluacion.referencia_familiar_1 &&
            this.detalleEvaluacion.referencia_familiar_1.estado ==
            this.router_path_filtro
          ) {
            return true;
          }
          if (
            this.detalleEvaluacion.referencia_familiar_2 &&
            this.detalleEvaluacion.referencia_familiar_2.estado ==
            this.router_path_filtro
          ) {
            return true;
          }

          if (
            this.detalleEvaluacion.referencia_personal_1 &&
            this.detalleEvaluacion.referencia_personal_1.estado ==
            this.router_path_filtro
          ) {
            return true;
          }
          if (
            this.detalleEvaluacion.referencia_personal_2 &&
            this.detalleEvaluacion.referencia_personal_2.estado ==
            this.router_path_filtro
          ) {
            return true;
          }
        }

        return false;
      }
    },
    flag_show_compromisos() {
      if (
        this.router_path_filtro &&
        this.router_path_filtro == 'CompromisosDePago'
      ) {
        return true;
      } else {
        return false;
      }
    },
    flag_show_rectifiaciones() {
      if (this.router_path_filtro && this.router_path_filtro == 'Rechazado') {
        return true;
      } else {
        return false;
      }
    },
    flag_show_paraaprobacion() {
      if (this.router_path_filtro && this.router_path_filtro == 'ParaAprobacion') {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    getBuroColor(resultado) {

      if (resultado == 'rechazado' || resultado == 'RECHAZADO' || resultado == 'DENEGADO' || resultado == 'DENEGAR') {
        return 'red'
      } else if (resultado == 'REVISIÓN' || resultado == 'revision' || resultado == 'revisión') {
        return 'orange'
      } else if (resultado == 'aprobado' || resultado == 'APROBADO') {
        return 'green'
      } else {
        return 'grey'
      }
    },
    ...mapActions('Rectificaciones', {
      findRectificaciones: 'find',
      getRectificaciones: 'get',
      patchRectificaciones: 'patch',
    }),
    ...mapActions('ComentariosInternosPorGestion', {
      createComentariosInternosPorGestion: 'create',
      findComentariosInternosPorGestion: 'find',
      getComentariosInternosPorGestion: 'get',
      patchComentariosInternosPorGestion: 'patch',
    }),
    async main() {
      let vm = this;
      await setTimeout(() => {
        vm.findRectificaciones({
          query: {
            gestion_id: vm.router_path_id,
            $limit: 100,
            perfil_de_usuario: 'deudor',
          },
        }).then((r) => {
          vm.comentarios_rectificaciones = r.data;
        });
      }, 700);

      await this.findComentariosInternosPorGestion({
        query: {
          gestion_id: this.router_path_id,
          $limit: 100,
          perfil_de_usuario: 'deudor',
          $sort: {
            createdAt: -1,
          },
        },
      }).then((r) => {
        console.log('comentarios internos: ', r);
        this.comentarios_internos = r.data;
      });
    },

    fnGetDate(date) {
      return date ? moment(date).format('DD-MM-YYYY') : '';
    },
    fnGetDateUTC(date) {
      return date ? moment(date).utc().format('DD-MM-YYYY') : '';
    },
    fnGetDateTime(date) {
      return date ? moment(date).format('DD-MM-YYYY HH:mm a') : '';
    },
    //clic en los botones de AGREGAR COMENTARIO
    fnAgregarComentarioUno() {
      console.log('comentario uno: ', this.comentarioUno);
      this.$refs.comentarioUno.reset(); //reiniciamos el text-fiel de comentario
    },
    async fn_agregar_comentario_interno() {
      console.log('comentario dos: ', this.comentario_interno);

      /**
       * tipo de comentario (contrato, doc identificaicon, foto casa, etc...)
       */
      let tipo_de_comentario = '';
      switch (this.tab) {
        case 0:
          tipo_de_comentario = 'contrato';
          break;
        case 1:
          tipo_de_comentario = 'complementos';
          break;
        case 2:
          tipo_de_comentario = 'evaluacion';
          break;
        case 3:
          tipo_de_comentario = 'referencias';
          break;
        default:
          tipo_de_comentario = '';
      }

      if (
        this.router_path_filtro &&
        this.router_path_filtro == 'CompromisosDePago'
      ) {
        tipo_de_comentario = 'compromiso';
      }
      /** */

      await this.createComentariosInternosPorGestion({
        gestion_id: this.detalleEvaluacion.id,
        comentario_tipo: tipo_de_comentario,
        perfil_de_usuario: 'deudor',

        comentario: this.comentario_interno,

        CREADO_POR_USUARIO: this.currentUser.usuario,
        CREADO_POR_USUARIO_NOMBRE: this.currentUser.nombre,
        CREADO_POR_USUARIO_ID: this.currentUser.id,

        // rectificacion_fecha: moment()
        //   .utc()
        //   .format('YYYY-MM-DDTHH:mm:ss.SSSS+00:00'),
      }).then((r) => {
        this.snackbar = true;
        this.comentarios_internos.push(r);
        this.$refs.comentario_interno.reset(); //reiniciamos el text-fiel de comentario
      });
    },
    async fn_agregar_comentario_externo() {
      console.log('comentario dos: ', this.comentario_interno);

      /**
       * tipo de comentario (contrato, doc identificaicon, foto casa, etc...)
       */
      let tipo_de_comentario = '';
      switch (this.tab) {
        case 0:
          tipo_de_comentario = 'contrato';
          break;
        case 1:
          tipo_de_comentario = 'complementos';
          break;
        case 2:
          tipo_de_comentario = 'evaluacion';
          break;
        case 3:
          tipo_de_comentario = 'referencias';
          break;
        default:
          tipo_de_comentario = '';
      }

      if (
        this.router_path_filtro &&
        this.router_path_filtro == 'CompromisosDePago'
      ) {
        tipo_de_comentario = 'compromiso';
      }
      /** */

      await this.createComentariosInternosPorGestion({
        gestion_id: this.detalleEvaluacion.id,
        comentario_tipo: tipo_de_comentario,
        perfil_de_usuario: 'deudor',
        flag_comentario_externo: 1,

        comentario: this.comentario_interno,

        CREADO_POR_USUARIO: this.currentUser.usuario,
        CREADO_POR_USUARIO_NOMBRE: this.currentUser.nombre,
        CREADO_POR_USUARIO_ID: this.currentUser.id,

        // rectificacion_fecha: moment()
        //   .utc()
        //   .format('YYYY-MM-DDTHH:mm:ss.SSSS+00:00'),
      }).then((r) => {
        this.snackbar = true;
        this.comentarios_internos.push(r);
        this.$refs.comentario_interno.reset(); //reiniciamos el text-fiel de comentario
      });
    },
  },
  async mounted() {
    await this.main();

    if (this.router_path_filtro == 'CompromisosDePago') {
      this.tab = 4;
    }
    if (this.router_path_filtro == 'ParaAprobacion') {
      this.tab = 4;
    }

    if (this.router_path_filtro == 'Pendiente') {
      setTimeout(() => {
        if (this.flag_show_contrato)
          this.tab = 0
        else if (this.flag_show_complemento)
          this.tab = 1
        else  if (this.flag_show_referencias)
          this.tab = 3
        else  if (this.flag_show_compromisos)
          this.tab = 4
        else  if (this.flag_show_rectifiaciones)
          this.tab = 4
        else  if (this.flag_show_paraaprobacion)
          this.tab = 4
        else
          this.tab = 4;
      }, 500);
    }
    let vm = this;
    setTimeout(() => {
      if (vm.router_path_filtro == 'Rechazado') {
        vm.tab = 4;
      }
    }, 300);

    /** */
    const { Rectificaciones } = this.$FeathersVuex.api;
    Rectificaciones.on('created', () => {
      this.main();
    });
  },
  watch: {},
};
</script>

<style scoped>
table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 6px;
}
</style>
