<template>
  <v-container fluid>
    <!-- {{ getParams() }} -->
    <v-row>
      <v-col>
        <v-card flat width="20%">
          <v-card-text>
            <v-menu ref="fechaDialog" v-model="modalFechas" :close-on-content-click="false" :nudge-right="-17"
              transition="scale-transition" offset-y min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="selectedRangueFecha" label="Días" prepend-icon="mdi-calendar" readonly
                  v-bind="attrs" v-on="on"></v-text-field>
              </template>
              <v-date-picker :selected-items-text="'$vuetify.datePicker.itemsSelected'" locale="es" width="240" no-title
                scrollable v-model="dialogVarSelectedRangueFecha" range :max="disabledFecha" color="primary"
                header-color="primary">
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modalFechas = false">
                  Cancelar
                </v-btn>
                <v-btn text color="primary" @click="$refs.fechaDialog.save(dialogVarSelectedRangueFecha)">
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>

          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-data-table disable-pagination :options.sync="options" :hide-default-footer="true" :headers="headers"
      :items="items">
      <template v-slot:top>
        <v-row no-gutters>
          <v-spacer>
          </v-spacer>
          <v-btn x-small @click="items=[], applyFilters()" class="mt-3 ml-1 mr-1 flo" color="primary">
            <v-icon small>mdi-refresh</v-icon>
          </v-btn>
          <v-btn x-small @click="applyFilters" class="mt-3 ml-1 mr-1 flo" color="primary">
            Búscar
          </v-btn>

          <v-btn x-small @click="exportToExcel" class="mt-3 ml-1 mr-1 flo" color="success">
            Exportar a Excel
          </v-btn>
          <v-btn x-small @click="exportToPDF" class="mt-3 ml-1 mr-1 flo" color="success">
            Exportar a PDF
          </v-btn>
        </v-row>
      </template>
      <template v-slot:header>
        <tr>
          <td v-for="header in filteredHeaders" :key="header.value">
            <div v-if="header.type === 'blank'"></div>
            <v-text-field @keydown.enter="applyFilters()" dense v-if="header.type === 'date' || header.type === 'datetime' || header.type === 'dateUTC'" v-model="filters[header.value] "
              :label="`${header.text}`" hide-details solo></v-text-field>
            <v-text-field @keydown.enter="applyFilters()" dense v-if="header.type === 'string'"
              v-model="filters[header.value]" :label="`${header.text}`" hide-details solo></v-text-field>
            <v-text-field @keydown.enter="applyFilters()" dense v-if="header.type === 'integer'" type="number" step="1"
              v-model.number="filters[header.value]" :label="`${header.text}`" hide-details solo></v-text-field>
            <v-select @change="applyFilters()" clearable dense v-if="header.type === 'boolean'"
              v-model="filters[header.value]" :items="booleanOptions" :label="`${header.text}`" hide-details
              solo></v-select>
            <!-- Add other input types as needed -->
          </td>
        </tr>

      </template>
      <template v-slot:[`item.nombre`]="{ item }">
        {{ item.primer_nombre }} {{ item.segundo_nombre }}
        {{ item.tercer_nombre }} {{ item.primer_apellido }}
        {{ item.segundo_apellido }} {{ item.tercer_apellido_o_casada }}
      </template>
      <template v-slot:[`item.campania`]="{ item }">
        {{ getYearMonth(item.fecha_campania) }}
      </template>
      <template v-slot:[`item.createdAt`]="{ item }">
        {{ fnGetDateTime(item.createdAt) }}
      </template>
      <template v-slot:[`item.flag_contrato_completado_fecha`]="{ item }">
        {{ fnGetDateTime(item.flag_contrato_completado_fecha) }}
      </template>
      <template v-slot:[`item.flag_tiene_pedido`]="{ item }">

        {{ formatCellValue(item.flag_tiene_pedido, 'boolean') }}

      </template>
      <template v-slot:[`item.fecha_campania`]="{ item }">
        {{ fnGetDateUTC(item.fecha_campania) }}
      </template>

      <template v-slot:[`item.comentarios`]="{ item }">
        <span  v-html="formatResumenComenentarioHTML(item.comentarios)"> </span>
      </template>

      <template v-slot:[`item.flag_contrato_completado`]="{ item }">
        {{ formatCellValue(item.flag_contrato_completado, 'boolean') }}
      </template>

      <template v-slot:[`item.flag_razon_contrato_incompleto`]="{ item }">
        <span v-if="item.flag_contrato_completado">COMPLETADO</span>
        <span v-else v-html="formatResumenContratoHTML(resumirContrato(item.contrato_info))"> </span>
      </template>
    </v-data-table>
    <v-row>
      <!-- PAGINACIÓN -->
      <v-col cols="12">
        <v-col offset="9" cols="3">
          <v-select v-model="pageSize" :items="pageSizes" label="Registros por página"
            @change="handlePageSizeChange"></v-select>
        </v-col>
        <v-col class="">
          Total de registros: {{ totalRegistros }}
          <v-pagination class="float-right" v-model="page" :length="totalPages" total-visible="15"
            next-icon="mdi-menu-right" prev-icon="mdi-menu-left" @input="handlePageChange"></v-pagination>
        </v-col>
      </v-col>
      <!-- FIN PAGINACIÓN -->
    </v-row>
  </v-container>
</template>
<script>
import moment from 'moment';
require('moment/locale/es.js');
import { mapActions } from 'vuex';

import jsPDF from 'jspdf';
import 'jspdf-autotable';

export default {
  data() {
    return {
      //variables para la fecha
      modalFechas: false,
      dialogVarSelectedRangueFecha: null,
      selectedRangueFecha: [
        moment().startOf('month').format('DD-MM-YYYY'),
        moment().format('DD-MM-YYYY'),
      ], //en esta variable es donde se almacenará la fecha o el rango de fechas
      disabledFecha: moment().format('YYYY-MM-DD'),
      //
      booleanOptions: [
        { text: 'Sí', value: 1 },
        { text: 'No', value: 0 },
      ],
      //pagination
      options: {},
      page: 1,
      totalPages: 0,
      pageSize: 10,
      pageSizes: [10, 25, 50, 100],
      totalRegistros: 0,
      //pagination fin
      headers: [
        { text: 'Gestión ID', value: 'id', type: 'integer', width: 90 },
        { text: 'Nombre Campaña', value: 'campania', type: 'string' },
        {
          text: 'Nombre persona',
          value: 'deudor_nombre_completo',
          type: 'string',
        },
        { text: 'Sector', value: 'sector', type: 'integer' },
        { text: 'Region', value: 'region', type: 'string' },
        { text: 'División', value: 'division', type: 'string' },
        { text: 'Fecha Evaluación', value: 'createdAt', type: 'datetime' },
        {
          text: 'Contrato completado',
          value: 'flag_contrato_completado',
          type: 'boolean',
        },
        {
          text: 'Fecha completa contrato',
          value: 'flag_contrato_completado_fecha',
          type: 'datetime',
        },
        {
          text: 'Razón contrato incompleto',
          value: 'flag_razon_contrato_incompleto',
          type: 'blank', width: 230
        },
        { text: 'Con pedido', value: 'flag_tiene_pedido', type: 'boolean' },
        { text: 'Comentarios', value: 'comentarios', type: 'string', width: 320 },

        // Add other headers with types
      ],
      items: [],
      filters: {
        id: '',
        campania: '',
        deudor_nombre_completo: '',
        sector: '',
        region: '',
        division: '',
        createdAt: '',
        flag_contrato_completado: '',
        flag_contrato_completado_fecha: '',
        flag_razon_contrato_incompleto: '',
        flag_tiene_pedido: '',
        comentarios: '',
      },
      filterConfig: {
        id: true,
        campania: true,
        deudor_nombre_completo: true,
        sector: true,
        region: true,
        division: true,
        createdAt: true,
        flag_contrato_completado: true,
        flag_contrato_completado_fecha: true,
        flag_razon_contrato_incompleto: true,
        flag_tiene_pedido: true,
        comentarios: true,
      },
    };
  },
  computed: {
    filteredHeaders() {
      return this.headers.filter((header) => this.filterConfig[header.value]);
    },
    isFilterChanged() {
      return Object.values(this.filters).some((filter) => filter !== '');
    },
  },
  watch: {
    options: {
      // eslint-disable-next-line no-unused-vars
      handler(newValue) {
        this.main();
      },
      deep: true,
      immediate: true,
    },
    dialogVarSelectedRangueFecha(newValue) {
      this.selectedRangueFecha = [
        moment(newValue[0]).format('DD-MM-YYYY'),
        moment(newValue[1]).format('DD-MM-YYYY'),
      ];
    },
  },
  methods: {

    resumirContrato(contrato) {
      console.log(contrato);

      const partes = [
        {
          nombre: "parte uno",
          fotografia: contrato?.contrato_parte_uno_fotografia,
          estado: contrato?.contrato_parte_uno_estado || 'sin revisar'
        },
        {
          nombre: "parte dos",
          fotografia: contrato?.contrato_parte_dos_fotografia,
          estado: contrato?.contrato_parte_dos_estado || 'sin revisar'
        },
        {
          nombre: "parte tres",
          fotografia: contrato?.contrato_parte_tres_fotografia,
          estado: contrato?.contrato_parte_tres_estado || 'sin revisar'
        }
      ];

      return partes.map(parte => {
        const existe = parte.fotografia != null;
        const estado = parte.estado !== null ? parte.estado : 'No aprobado';
        return {
          nombre: parte.nombre,
          existe: existe ? 'Existe fotografía' : 'Sin archivo',
          estado: estado
        };
      });
    },
    formatResumenContrato(resumen) {
      return resumen.map(parte =>
        `${parte.nombre}: ${parte.existe} - ${parte.estado} `
      ).join('\n');
    },
    formatResumenContratoHTML(resumen) {
      return resumen.map(parte =>
        `<div style="font-size: 10px;">${parte.nombre}: ${parte.existe} - ${parte.estado}</div>`
      ).join('');
    },
    formatResumenComenentarioHTML(texto) {
      if (!texto || texto == '') {
        return 'Sin comentarios'
      }
      

      const arreglo = texto.split(" , ").map(item => {
        const [idPart, comentarioPart] = item.split(", ");
        const id = idPart.split(": ")[1]; // Obtener el valor del ID
        if (!comentarioPart) {
          console.log('comentario formato html', item);

          return { tipo: '', comentario: item };
          
        }
        const comentario = comentarioPart.split(": ")[1]; // Obtener el comentario
        return { tipo: id, comentario };
      });

      
      return arreglo.map(parte =>
        `<div style="font-size: 10px;">${parte.tipo}: ${parte.comentario}</div>`
      ).join('');
    },
    async exportToPDF() {
      try {
        // Obtener todos los registros
        const allRecords = await this.getAllRecords();

        // Crear el documento PDF en orientación vertical
        const doc = new jsPDF('p', 'pt', 'a4');

        // Preparar los datos para la tabla
        const tableData = allRecords.map((record) =>
          this.headers.map((header) => {
            if (header.value === 'flag_razon_contrato_incompleto') {
              if (record.flag_contrato_completado) {
                return 'COMPLETADO';
              } else {
                return this.formatResumenContrato(this.resumirContrato(record.contrato_info));
              }
            } else {
              return this.formatCellValue(record[header.value], header.type);
            }
          }
          )
        );

        // Definir las columnas
        const tableColumns = this.headers.map((header) => ({
          header: header.text,
          dataKey: header.value,
        }));

        // Calcular el ancho de página disponible
        const pageWidth = doc.internal.pageSize.getWidth();

        // Añadir la tabla al PDF
        doc.autoTable({
          head: [tableColumns.map((col) => col.header)],
          body: tableData,
          startY: 20,
          styles: {
            fontSize: 6,
            cellPadding: 2,
          },
          headStyles: {
            fillColor: [41, 128, 185],
            textColor: 255,
            fontSize: 7,
            fontStyle: 'bold',
          },
          columnStyles: {
            0: { cellWidth: 'auto' }, // ID column
            1: { cellWidth: 'auto' }, // Adjust as needed for other columns
          },
          alternateRowStyles: { fillColor: [245, 245, 245] },
          margin: { top: 20, right: 10, bottom: 20, left: 10 },
          tableWidth: pageWidth - 20, // Full width minus margins
        });

        // Guardar el archivo PDF
        doc.save('LISTADO DE CONTRATOS COMPLETOS E INCOMPLETOS.pdf');
      } catch (error) {
        console.error('Error al exportar a PDF:', error);
        // Aquí puedes agregar un manejo de errores más robusto, como mostrar un mensaje al usuario
      }
    },

    formatCellValue(value, type) {
      if (type === 'datetimeUTC') {
        return this.fnGetDateUTCTime(value);
      }
      if (type === 'dateUTC') {
        return this.fnGetDateUTC(value);
      }
      if (type === 'date') {
        return this.fnGetDate(value);
      }
      if (type === 'datetime') {
        return this.fnGetDateTime(value);
      }
      if (type === 'boolean') {
        if (value == 1) {
          return 'Si'
        }
        else if (value == 0) {
          return 'No'
        } else {
          return '-'
        }
      }
      // Manejar otros tipos según sea necesario
      return value;
    },
    /** fin pdf */
    async exportToExcel() {
      try {
        let XLSX = require('xlsx');
        // Obtener todos los registros
        const allRecords = await this.getAllRecords();

        // Preparar los datos para el Excel
        const excelData = allRecords.map((record) => {
          let rowData = {};
          this.headers.forEach((header) => {
            // Usar el 'text' como nombre de columna y 'value' para obtener el dato
            if (header.value === 'flag_razon_contrato_incompleto') {
              if (record.flag_contrato_completado) {
                rowData[header.text] = 'COMPLETADO';
              } else {
                rowData[header.text] = this.formatResumenContrato(this.resumirContrato(record.contrato_info));
              }
            } else {
              rowData[header.text] = this.formatCellValue(record[header.value], header.type);
            }
          });
          return rowData;
        });

        // Crear una hoja de trabajo
        const ws = XLSX.utils.json_to_sheet(excelData);

        // Crear un libro de trabajo y agregar la hoja
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Registros');

        // Guardar el archivo
        XLSX.writeFile(wb, 'LISTADO DE CONTRATOS COMPLETOS E INCOMPLETOS.xlsx');
      } catch (error) {
        console.error('Error al exportar a Excel:', error);
        // Aquí puedes agregar un manejo de errores más robusto, como mostrar un mensaje al usuario
      }
    },

    async getAllRecords() {
      const totalRecords = this.totalRegistros;
      const params = this.getParams();
      params['$limit'] = totalRecords;
      params['$skip'] = 0;

      try {
        const response = await this.findGestiones({ query: params });
        console.log('todas las gestiones: ', response);
        return response.data;
      } catch (error) {
        console.error('Error al obtener todos los registros:', error);
        throw error;
      }
    },
    fnGetDateUTC(date) {
      return date ? moment(date).utc().format('DD-MM-YYYY') : '';
    },
    fnGetDateUTCTime(date) {
      return date ? moment(date).utc().format('DD-MM-YYYY HH:mm:ss a') : '';
    },
    fnGetDateTime(date) {
      return date ? moment(date).format('DD-MM-YYYY HH:mm:ss a') : '';
    },
    fnGetDate(date) {
      return date ? moment(date).format('DD-MM-YYYY') : '';
    },
    getYearMonth(dateString) {
      // Parse the date string to a Date object
      const date = new Date(dateString);

      // Extract the year and month
      const year = date.getFullYear();
      // Months are zero-indexed, so add 1 and pad with zero if necessary
      const month = String(date.getMonth() + 1).padStart(2, '0');

      // Concatenate year and month
      return `${year}${month}`;
    },
    //
    ...mapActions('GestionesEventosComentarios', {
      findGestiones: 'find',
      createGestiones: 'create',
      patchGestiones: 'patch',
    }),
    /**paginacion */
    handlePageChange(value) {
      this.page = value;
      this.main();
    },
    handlePageSizeChange(size) {
      this.pageSize = size;
      this.page = 1;
      this.main();
    },
    /**paginacion fin */
    getParams() {
      const params = {};
      params['$limit'] = this.pageSize;
      params['$skip'] = (this.page - 1) * this.pageSize;
      /** */
      /**fechas */
      /** */
      let split1 = this.selectedRangueFecha[0].split('-');
      let split2 = this.selectedRangueFecha[1].split('-');
      var firstDay = new Date(split1[2], split1[1] - 1, split1[0], 0, 0, 0);
      var lastDay = new Date(split2[2], split2[1] - 1, split2[0], 23, 59, 59);
      //console.log('fechas para createdAt', firstDay, lastDay);
      params['createdAt'] = {
        $gte: firstDay,
        $lte: lastDay,
      };
      /** */
      /**fechas */

      Object.keys(this.filters).forEach((key) => {
        let filterValue = this.filters[key];
        if (filterValue === '' || filterValue === null || filterValue === undefined) return;

        const header = this.headers.find((h) => h.value === key);
        if (!header) return;

        if (header.type === 'string' && !header.multi) {
          // Trim leading and trailing spaces
          filterValue = filterValue.trim();
          params[key] = { $like: `%${filterValue}%` };
        } else if (header.type === 'integer') {
          params[key] = filterValue;
        } else if (header.type === 'boolean') {
          params[key] = filterValue;
          params[key] = filterValue === 0 ? { $eq: null } : Boolean(filterValue);
        } else if (header.type === 'date') {
          filterValue = filterValue.trim();

          let split_filterValue = filterValue.split('-');
          var firstDay = new Date(split_filterValue[2], split_filterValue[1] - 1, split_filterValue[0], 0, 0, 0);
          var lastDay = new Date(split_filterValue[2], split_filterValue[1] - 1, split_filterValue[0], 23, 59, 59);


          params[key] = {
            $gte: firstDay,
            $lte: lastDay,
          };

        }
        // Add more conditions for other types like 'betweendate'
      });
      /** */
      if (
        this.options.sortBy &&
        this.options.sortBy.length === 1 &&
        this.options.sortDesc.length === 1
      ) {
        let sorting = {};
        let flag = this.options.sortDesc[0] ? -1 : 1;
        sorting[this.options.sortBy[0].toString()] = flag;
        params['$sort'] = sorting;
      } else {
        params['$sort'] = { id: -1 };
      }

      return params;
    },
    /** */
    main() {
      this.findGestiones({ query: this.getParams() }).then((r) => {
        this.items = r.data;
        this.totalRegistros = r.total;
        this.totalPages = Math.ceil(
          parseInt(r.total) / parseInt(this.pageSize)
        );
      });
    },
    /** */
    applyFilters() {
      this.page = 1;
      this.main();
      // Function to apply filters and fetch data
      // eslint-disable-next-line no-unused-vars
      const params = this.getParams();
      // Use the params to fetch data from Feathers service
    },
  },
  mounted() {
    this.main();
  },
};
</script>
