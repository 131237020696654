<template>
  <v-container fluid>
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="modalBuroDeudor = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        deudor
        <v-spacer></v-spacer>
        <v-btn :loading="print_loading_flag" icon dark @click="imprimirComponente('deudor')">
          <v-icon>mdi-printer</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <div ref="deudor">
          <GestionBuroDeudor :detalleEvaluacion="detalleEvaluacion"></GestionBuroDeudor>
        </div>
      </v-card-text>
    </v-card>




    <v-row>
      <v-col cols="12" class="mb-5">
        <p class="text-h6 font-weight-regular">Revisión evaluación</p>
      </v-col>
      <v-col cols="4" md="4">
        <v-select label="Tipo de Cliente" @change="fnActualizarTipoDeUsuario()" :disabled="flag_gestion_terminada"
          v-model="detalleEvaluacion.buro_tipo_de_usuario" :items="itemsReingreso" prepend-icon="mdi-database-search"
          dense></v-select>
      </v-col>
      <v-col cols="4" md="4">
        <v-select label="¿Tiene pedido?" @change="fnActualizarTienePedido()" :disabled="flag_gestion_terminada"
          v-model="detalleEvaluacion.flag_tiene_pedido" :items="itemsPedido" prepend-icon="mdi-database-search"
          dense></v-select>
      </v-col>
      <v-col cols="4" md="4">

        <v-textarea :hint="comentarioHint" persistent-hint ref="comentario" :disabled="flag_gestion_terminada"
          v-model="detalleEvaluacion.flag_tiene_pedido_comentario" label="Agregar comentario" auto-grow outlined
          rows="4" row-height="25" :counter="250"
          :rules="[v => (v && v.length <= 250) || 'Máximo 250 caracteres']"></v-textarea>


      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import GestionBuroDeudor from '../../gestion_buro_deudor.vue';
import debounce from 'lodash/debounce';
import { mapActions } from 'vuex';
import moment from 'moment';
require('moment/locale/es.js');

export default {
  name: 'vistaEvaluacion',
  props: ['detalleEvaluacion'],
  components: {
    GestionBuroDeudor,
  },
  data() {
    return {
      shouldWatch: false, // Bandera para controlar el watch
      print_loading_flag: false,
      //
      isBuroInternoDialogOpen: false,
      isEquifaxDialogOpen: false,
      //
      reingresoSelect: null, //variable para almacenar el valor del reingreso seleccionado
      //datos temporales con valores para el select de "Reingreso"
      itemsReingreso: ['reingreso', 'nuevo'],
      itemsPedido: [
        { text: 'Si', value: 1 },
        { text: 'No', value: 0 },
      ],
    };
  },
  mounted() {
    // Activa el watch después de que el componente se haya montado
    setTimeout(() => {
      this.shouldWatch = true;
    }, 500);
  },
  computed: {
    comentarioHint() {
      if (!this.detalleEvaluacion.flag_tiene_pedido_comentario || this.detalleEvaluacion.flag_tiene_pedido_comentario == '') {
        return ''
      } else {
        if (!this.detalleEvaluacion.flag_tiene_pedido_usuario_web_codigo) {
          return ''
        } else {
          return 'Comentario realizado por: ' + this.detalleEvaluacion.flag_tiene_pedido_usuario_web_codigo + ' el ' + this.fnGetDateTime(this.detalleEvaluacion.flag_tiene_pedido_usuario_web_fecha)
        }

      }
    },
    currentUser() {
      return this.$store.state.auth.payload.usuario;
    },
    flag_gestion_terminada() {
      if (this.detalleEvaluacion.flag_precalificacion_concluidas == 1) {
        return true
      } else if (this.detalleEvaluacion.flag_precalificacion_cancelada == 1) {
        return true
      } else if (this.detalleEvaluacion.flag_precalificacion_eliminada == 1) {
        return true
      } else {
        return false
      }
    },
    buroInternoResult() {
      // Parse the JSON string from resultado_evaluacion_buro_interno
      try {
        return JSON.parse(this.detalleEvaluacion.resultado_evaluacion_buro_interno);
      } catch (e) {
        return {};
      }
    },
    politicasInternasResult() {
      // Parse the JSON string from resultado_evaluacion_politicas_internas
      try {
        return JSON.parse(this.detalleEvaluacion.resultado_evaluacion_politicas_internas);
      } catch (e) {
        return {};
      }
    },
    equifaxResult() {
      // Equifax data could be JSON or string, try parsing it
      let equifaxData = this.detalleEvaluacion.resultado_evaluacion_equifax_detalle || '';
      try {
        return JSON.parse(equifaxData);
      } catch (e) {
        return equifaxData;
      }
    },
    isEquifaxJson() {
      return typeof this.equifaxResult === 'object';
    },
    parsedEquifaxDetail() {
      return this.isEquifaxJson ? JSON.stringify(this.equifaxResult, null, 2) : this.equifaxResult;
    }
  },
  watch: {
    'detalleEvaluacion.flag_tiene_pedido_comentario': function () {
      if (this.shouldWatch) {
        this.fnActualizarComentario();
      }
    }
  },
  methods: {
    imprimirComponente(perfil) {
      this.print_loading_flag = true;
      this.$nextTick(() => {

        /** */
        let contenidoParaImprimir;
        if (perfil === 'deudor') {
          contenidoParaImprimir = this.$refs.deudor.cloneNode(true);
        } else if (perfil === 'fiador') {
          contenidoParaImprimir = this.$refs.fiador.cloneNode(true);
        } else {
          console.error("Perfil no reconocido. Debe ser 'deudor' o 'fiador'.");
          this.print_loading_flag = false;
          return;
        }
        /** */
        const ventanaImpresion = window.open('', '', 'height=1500, width=1500');

        ventanaImpresion.document.write(`
          <html>
            <head>
              <!-- Vuetify CSS -->
              <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/vuetify@2.6.0/dist/vuetify.min.css">
              
              <!-- Material Icons -->
              <link href="https://fonts.googleapis.com/css2?family=Material+Icons" rel="stylesheet">
              <link href="https://fonts.googleapis.com/css2?family=Material+Icons+Outlined" rel="stylesheet">
              <link href="https://fonts.googleapis.com/css2?family=Material+Icons+Round" rel="stylesheet">
              <link href="https://fonts.googleapis.com/css2?family=Material+Icons+Sharp" rel="stylesheet">
              <link href="https://fonts.googleapis.com/css2?family=Material+Icons+Two+Tone" rel="stylesheet">
              <link href="https://cdn.jsdelivr.net/npm/@mdi/font@7.x/css/materialdesignicons.min.css" rel="stylesheet">

              <style>
                @page {
                  //background-color:yellow;
                  size: 1200px;
                  margin: 10mm;
                  margin-top: 0 !important;
                  top: 0 !important;
                }

                @media print {
                  html, body {
                    font-family: Roboto, Arial, sans-serif;
                    //background-color: red;
                    margin: 0;
                    padding: 0;
                    width: 100%;
                    max-width: 100%;
                    overflow: hidden;
                  }


                  .v-application {
                    width: 100% !important;
                    max-width: 100% !important;
                    margin: 0 !important;
                    padding: 0 !important;
                    overflow: visible !important;
                    //background-color: blue !important;
                    top: 0 !important;
                    position: absolute; top:0px;
                  }

                  .v-application .text-body-2 {
                      font-size: .68rem !important;
                      font-family: Roboto, sans-serif !important;
                  }

                  table {
                    //background-color: cyan;
                    width: 100% !important;
                    border-collapse: collapse !important;
                  }

                  th, td {
                    border: 1px solid #fff !important;
                    text-align: left !important;
                    padding: 3px !important; /* Reduce el padding */
                    font-size: 12px !important; /* Ajusta el tamaño de fuente */
                    line-height: 0.75 !important; /* Reduce la altura de las filas */
                  }
                  th {
                    background-color: #f5f5f5 !important;
                    font-weight: bold !important;
                  }
          
                  * {
                    visibility: visible;
                    box-sizing: border-box;
                    color-adjust: exact;
                    -webkit-print-color-adjust: exact;
                    print-color-adjust: exact;
                  }

                  .no-print {
                    display: none !important;
                  }

                  .mdi {
                    font-family: "Material Design Icons" !important;
                    font-size: 24px; /* Ajusta el tamaño según lo necesario */
                    font-weight: normal;
                    font-style: normal;
                    display: inline-block;
                    line-height: 1;
                    text-transform: none;
                    letter-spacing: normal;
                    word-wrap: normal;
                    white-space: nowrap;
                    direction: ltr;
                  }

                  .material-icons {
                    font-family: 'Material Icons' !important;
                    font-size: 24px !important;
                    font-weight: normal;
                    font-style: normal;
                    display: inline-block;
                    line-height: 1;
                    text-transform: none;
                    letter-spacing: normal;
                    word-wrap: normal;
                    white-space: nowrap;
                    direction: ltr;
                  }

                }
              </style>
            </head>
            <body style="display:block!important;" class="v-application v-application--is-ltr theme--light">
          `);

        ventanaImpresion.document.body.appendChild(contenidoParaImprimir);
        ventanaImpresion.document.write('</body></html>');
        ventanaImpresion.document.close();

        setTimeout(() => {
          ventanaImpresion.print();
          ventanaImpresion.close();
          this.print_loading_flag = false;
        }, 1000);
      });
    },
    async printPDF(tipo) {
      this.print_loading_flag = true;
      try {
        this.print_loading_flag = true;

        // Obtener el elemento completo
        const element = this.$refs[tipo];

        // Capturar el contenido
        const canvas = await html2canvas(element, {
          scale: 2,
          useCORS: true,
          logging: true,
          allowTaint: true,
          windowWidth: 2200,
          windowHeight: element.scrollHeight
        });

        // Crear PDF del tamaño del contenido
        const imgWidth = 2200; // Ancho fijo en mm (tamaño carta)
        const imgHeight = (canvas.height * imgWidth) / canvas.width;

        const pdf = new jsPDF('p', 'mm', [imgWidth + 20, imgHeight + 20]); // +20 para márgenes

        pdf.setLineWidth(0); // Set line width to 0
        pdf.setDrawColor(255, 255, 255, 0); // Set line color to transparent

        const imgData = canvas.toDataURL('image/jpeg', 1.0);
        pdf.addImage(imgData, 'JPEG', 10, 10, 2200, imgHeight);

        pdf.save(`reporte buro evaluacion ${this.detalleEvaluacion.id} (${tipo}).pdf`);
        this.print_loading_flag = false;

      } catch (error) {
        console.error('Error al generar PDF:', error);
        this.print_loading_flag = false;
      }
      this.print_loading_flag = false;
    },
    fnActualizarComentario: debounce(function () {
      this.patchGestiones([this.detalleEvaluacion.id, {
        flag_tiene_pedido_comentario: this.detalleEvaluacion.flag_tiene_pedido_comentario,
        flag_tiene_pedido_usuario_web_codigo: this.currentUser.usuario,
        flag_tiene_pedido_usuario_web_nombre: this.currentUser.nombre,
        flag_tiene_pedido_usuario_web_id: this.currentUser.id,
        flag_tiene_pedido_usuario_web_fecha: moment().utc().format('YYYY-MM-DDTHH:mm:ss.SSSS+00:00'),
      }]).then(() => {
        this.$notify({
          title: 'Gestiones',
          text: 'Se ha actualizado el comentario de pedido.',
          color: '#4caf50',
          timeout: 3000,
        });
      });
    }, 1000),  // 1000 milisegundos (1 segundo) de retraso
    fnActualizarTipoDeUsuario() {
      this.patchGestiones([this.detalleEvaluacion.id, {
        buro_tipo_de_usuario: this.detalleEvaluacion.buro_tipo_de_usuario
      }]).then(() => {
        this.$notify({
          title: 'Gestiones',
          text:
            'Se ha actualizado el tipo de cliente.',
          color: '#4caf50',
          timeout: 3000,
        });
      })
    },
    fnActualizarTienePedido() {
      this.patchGestiones([this.detalleEvaluacion.id, {
        flag_tiene_pedido: this.detalleEvaluacion.flag_tiene_pedido
      }]).then(() => {
        this.$notify({
          title: 'Gestiones',
          text:
            'Se ha actualizado el tipo de cliente.',
          color: '#4caf50',
          timeout: 3000,
        });
      })
    },
    ...mapActions('Gestiones', {
      findGestiones: 'find',
      createGestiones: 'create',
      patchGestiones: 'patch',
    }),
    fnGetDate(date) {
      return date ? moment(date).format('DD-MM-YYYY') : '';
    },
    fnGetDateUTC(date) {
      return date ? moment(date).utc().format('DD-MM-YYYY') : '';
    },
    fnGetDateTime(date) {
      return date ? moment(date).format('DD-MM-YYYY HH:mm:ss a') : '';
    },
    getColor(estado) {
      if (estado == 'rechazado' || estado == 'RECHAZADO' || estado == 'DENEGADO' || estado == 'DENEGAR') {
        return 'red'
      } else if (estado == 'REVISIÓN' || estado == 'revision' || estado == 'revisión') {
        return 'orange'
      } else if (estado == 'aprobado' || estado == 'APROBADO') {
        return 'green'
      } else {
        return 'grey'
      }

    },
    showBuroInternoDetails() {
      this.isBuroInternoDialogOpen = true;
    },
    showEquifaxDetails() {
      this.isEquifaxDialogOpen = true;
    }
  }
};
</script>

<style scoped>
table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 3px;
}

.font {
  font-size: 9px;
}

.v-card {
  margin-bottom: 20px;
}
</style>
